import {
  useState,
  useContext,
  useEffect,
  createContext,
  ReactNode,
} from 'react';
import Axios from 'axios';

import { routes } from '../routes';
import { accountService } from '../services';
import { Spinner } from '../components/common';
import { hasPermission } from '../utils/permission';
import { AppRouteType, DynamicObject, Account } from '../models';
import { setAxiosToken, removeAxiosToken } from '../services/request';
import {
  createSearchParams,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

function useMergeState<Payload>(
  initialState: Payload
): [state: Payload, setMergedState: Function] {
  const [state, setState] = useState<Payload>(initialState);
  const setMergedState = (newState: DynamicObject<any>) =>
    setState((prevState) => ({ ...prevState, ...newState }));
  return [state, setMergedState];
}

interface AuthContextType {
  loading: boolean;
  AccessToken: null | string | undefined;
  profile: null | Account;
  isAuth: boolean;
  rememberLogin: {
    remember: boolean;
    email: string;
    password: string;
  };
  onLogin: (credentials: {
    email: string;
    password: string;
    remember: boolean;
  }) => Promise<any>;
  onSignOut: () => void;
  getProfile: () => void;
}

const AuthContext = createContext<AuthContextType>({
  loading: true,
  AccessToken: null,
  profile: null,
  isAuth: false,
  rememberLogin: {
    remember: false,
    email: '',
    password: '',
  },
  onLogin: () => Promise.resolve(),
  onSignOut: () => {},
  getProfile: () => {},
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useMergeState({
    loading: true,
    AccessToken: sessionStorage.getItem('AccessToken'),
    isAuth: false,
    profile: null,
    rememberLogin: {
      remember: false,
      email: '',
      password: '',
    },
  });

  const currentPage = routes?.find((_: AppRouteType) =>
    matchPath(_.path, location.pathname) ? true : false
  );

  const onLogin = async (credentials: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    try {
      setUserData({ loading: true });
      const result = await accountService.signIn(credentials);
      searchParams.delete('token');
      setSearchParams(searchParams);
      setAxiosToken(result.AccessToken);
      sessionStorage.setItem('AccessToken', result.AccessToken);
      setUserData({
        AccessToken: result.AccessToken,
        rememberLogin: credentials.remember
          ? credentials
          : {
            remember: false,
            email: null,
            password: null,
          },
      });
    } catch (error: any) {
      setUserData({ loading: false });
      throw new Error(error);
    }
  };

  const getProfile = async () => {
    try {
      setUserData({ loading: true });
      const user= await accountService.profile();
      setUserData({
        loading: false,
        profile: user,
        isAuth: true,
      });
      if (currentPage?.name === 'Login') {
        navigate('/', { replace: true });
      }
    } catch (error: any) {
        onSignOut();
    }
  };

  useEffect(() => {
    if (!userData?.AccessToken) {
      setUserData({ loading: false });
    }
    const token = searchParams.get('token');
    // router.asPath &&
    //   router.asPath.includes('token') &&
    //   router.query.token == undefined &&
    //   userData?.AccessToken
    if (token && userData?.AccessToken) {
      // only for the first load
      onSignOut();
    } else {
      if (!currentPage) {
        navigate('/', { replace: true });
      }
      if (!userData?.AccessToken && currentPage?.auth) {
        setUserData({ loading: false });
        navigate('/login', { replace: true }); // If not authenticated, force log in
      }
      if (userData?.AccessToken && !userData.profile) {
        setAxiosToken(userData?.AccessToken);
        getProfile();
      }
    }
  }, [userData?.AccessToken, currentPage]);

  useEffect(() => {
    if (userData?.AccessToken && userData?.profile) {
      // check permission
      if (
        currentPage?.roles &&
        !hasPermission(currentPage.roles, userData.profile)
      ) {
        navigate('/404', { replace: true });
      } else if (!currentPage?.auth && !currentPage?.isPublic) {
        // if already signed in, auto redirect to homepage if accesss to non-auth page
        navigate('/', { replace: true });
      }
    }
  }, [userData?.AccessToken, userData?.profile, currentPage]);

  const onSignOut = async () => {
    try {
      if (userData?.profile) {
        await accountService.signOut();
      }
    } catch (error) {}
    setUserData({
      loading: false,
      AccessToken: null,
      isAuth: false,
      profile: null,
    });
    sessionStorage.removeItem('AccessToken');
    removeAxiosToken();
    const tokenQuery = searchParams.get('token');
    navigate(
      {
        pathname: '/login',
        search: tokenQuery
          ? createSearchParams({
              token: tokenQuery,
            }).toString()
          : '',
      },
      { replace: true }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        onLogin,
        onSignOut,
        getProfile,
        loading: userData.loading,
        AccessToken: userData.AccessToken,
        rememberLogin: userData.rememberLogin,
        profile: userData.profile,
        isAuth: userData.isAuth,
      }}
    >
      {userData?.loading ||
      (currentPage?.roles &&
        !hasPermission(currentPage.roles, userData?.profile)) ? (
        <Spinner />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
