import { Account } from '../models';

export interface RoleInterface {
  ADMIN: number;
  MEMBER: number;
}

export const roles = {
  ADMIN: 1,
  MEMBER: 2,
};

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
export function hasPermission(
  role: number | undefined,
  profile: null | Account
) {
  if (role) {
    if (profile?.role === role) {
      return true;
    }
    return false;
  }
  return true;
}
