import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from '../components/common';
import { AppRouteType } from '../models';
import { hasPermission } from '../utils/permission';
import useAuthContext from '../store/auth-context';
import Main from "../components/layout/Main";
const Login = lazy(() => import('../pages/Login'));
const NotFound = lazy(() => import('../pages/404'));
const Home = lazy(() => import('../pages/Home'));
const ProjectList = lazy(() => import('../pages/ProjectList'));
const ProjectDetails = lazy(() => import('../pages/ProjectDetails'));
const Profile = lazy(() => import('../pages/Profile'));
const  ProjectType = lazy(() => import('../components/report/projectType'));
const  DataSummaryReport = lazy(() => import('../components/report/dataSummaryReport'));
const  FileUploadStatus = lazy(() => import('../components/report/fileUploadStatusReport'));
const  StratificationGraphReport = lazy(() => import('../components/report/stratificationGraphReport'));
const  MultigraphReport = lazy(() => import('../components/report/multigraphReport'));
const  LoggerAllocationTableReport = lazy(() => import('../components/report/loggerAllocationTableReport'));
const  InternalExternalTempComparisonReport = lazy(() => import('../components/report/internalExternalTempComparisonReport'));
const  ExcursionAnalysisReport = lazy(() => import('../components/report/excursionAnalysisReport'));
const  ClientMappingReminderReport = lazy(() => import('../components/report/clientMappingReminderReport'));


const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routePage = {
  BASE: 0,
  GROUPE: 1,
  POINTS_DE_COLLECTS: 2,
};

export const routes: AppRouteType[] = [
  {
    name: 'Login',
    auth: false,
    path: 'login',
    component: Login,
  },
  {
    name: '404',
    path: '404',
    component: NotFound,
    isPublic: true,
  },
  {
    name: 'Dashboard',
    // auth: true,
    path: '/',
    component: Home,
    isPublic: true,
  },
  {
    name: 'List of projects',
    // auth: true,
    path: '/projects',
    component: ProjectList,
    isPublic: true,
  },
  {
    name: 'Project Details',
    // auth: true,
    path: '/project-details',
    component: ProjectDetails,
    isPublic: true,
  },
  {
    name: 'Profile',
    // auth: true,
    path: '/profile',
    component: Profile,
    isPublic: true,
  },
  {
    name: 'ProjectType',
    // auth: true,
    path: '/projects/:projectId',
    component: ProjectType,
    isPublic: true,
  },
  {
    name: 'DataSumaryReport',
    // auth: true,
    path: '/projects/:projectId/data-summary-report',
    component: DataSummaryReport,
    isPublic: true,
  },
  {
    name: 'FileUploadStatus',
    // auth: true,
    path: '/projects/:projectId/file-upload-status',
    component: FileUploadStatus,
    isPublic: true,
  },
  {
    name: 'ClientMappingReminderReport',
    // auth: true,
    path: '/projects/:projectId/client-mapping-reminder-report',
    component: ClientMappingReminderReport,
    isPublic: true,
  },
  {
    name: 'ExcursionAnalysisReport',
    // auth: true,
    path: '/projects/:projectId/excursion-analysis-report',
    component: ExcursionAnalysisReport,
    isPublic: true,
  },
  {
    name: 'InternalExternalTempComparisonReport',
    // auth: true,
    path: '/projects/:projectId/internal-external-temp-comparison-report',
    component: InternalExternalTempComparisonReport,
    isPublic: true,
  },
  {
    name: 'LoggerAllocationTableReport',
    // auth: true,
    path: '/projects/:projectId/logger-allocation-table-report',
    component: LoggerAllocationTableReport,
    isPublic: true,
  },
  {
    name: 'MultigraphReport',
    // auth: true,
    path: '/projects/:projectId/multigraph-report',
    component: MultigraphReport,
    isPublic: true,
  },
  {
    name: 'StratificationGraphReport',
    // auth: true,
    path: '/projects/:projectId/stratification-graph-report',
    component: StratificationGraphReport,
    isPublic: true,
  },
  
];

export default function RoutesAppRoutes() {
  const { AccessToken, profile } = useAuthContext();

  const publicRoutes = routes
    .filter((route) => !route.auth || route.isPublic)
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  // public routes
  if (!AccessToken) return <Main><Routes>{publicRoutes}</Routes></Main>;

  // authenticated routes
  const authenticatedRoutes = routes
    .filter(
      (route) =>
        (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  return (
    <Main>
      <Routes>
        {authenticatedRoutes}
      </Routes>
    </Main>
  );
}
