import request from './request';
import { Account } from '../models';

const accountService = {
  signIn: (query: { email: string; password: string }) =>
    request.post<{ AccessToken: string }>('/api/auth/sign-in', query),
  profile: () => request.post<{ user: Account }>('/api/auth/user-profile'),
  forgetPassword: (query: any) => request.post('/forget-password', query),
  signOut: () => request.get('/logout-pups-account'),
};

export default accountService;
